import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setShirtPrint, setShirtHand, setShirtBottom, setShirtResult} from "../store/mainReducer"


const ConstructPage = () => {
    const dispatch = useDispatch()
    const shirtPrint = useSelector(state=>state.main.shirtPrint)
    const shirtHand = useSelector(state=>state.main.shirtHand)
    const shirtBottom = useSelector(state=>state.main.shirtBottom)

    const createShirt = ()=> {
        const shirtResult = [shirtPrint, shirtHand, shirtBottom].join('')
        dispatch(setShirtResult(+shirtResult))
        dispatch(setActivePanel('loadingpage'))
    }

    return (
        <div className="container common-page">
            <div className="filter-wrapper">
                <p className="caption filter-wrapper__caption">ВЫБИРАЙ<br/>ЭЛЕМЕНТЫ</p>
                <div className="filter-block">
                    <p className="filter-block__caption">Принт на груди</p>
                    <div className="filter-block__items">
                        <div className="input-item input-item_type_chest">
                            <input type="checkbox" id="chest-print1"
                                onChange={()=>dispatch(setShirtPrint(1))} 
                                checked={shirtPrint === 1 ? true : false}
                            />
                            <label htmlFor="chest-print1">
                                <span>
                                    <img src="assets/images/print-preview1.png" width="38" height="33" alt="" />
                                </span>
                            </label>
                        </div>
                        <div className="input-item input-item_type_chest">
                            <input type="checkbox" id="chest-print2"
                                onChange={()=>dispatch(setShirtPrint(2))} 
                                checked={shirtPrint === 2 ? true : false}
                            />
                            <label htmlFor="chest-print2">
                                <span>
                                <img src="assets/images/print-preview2.png" width="35" height="15" alt="" />
                                </span>
                            </label>
                        </div>
                        <div className="input-item input-item_type_chest">
                            <input type="checkbox" id="chest-print3"
                                onChange={()=>dispatch(setShirtPrint(3))} 
                                checked={shirtPrint === 3 ? true : false}
                            />
                            <label htmlFor="chest-print3">
                                <span>
                                <img src="assets/images/print-preview3.png" width="22" height="33" alt="" />
                                </span>
                            </label>
                        </div>
                        <div className="input-item input-item_type_chest">
                            <input type="checkbox" id="chest-print4"
                                onChange={()=>dispatch(setShirtPrint(4))} 
                                checked={shirtPrint === 4 ? true : false}
                            />
                            <label htmlFor="chest-print4">
                                <span>
                                <img src="assets/images/print-preview4.png" width="31" height="33" alt="" />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-block__caption">Принт на рукаве</p>
                    <div className="filter-block__items">
                        <div className="input-item input-item_type_hand">
                            <input 
                                type="checkbox" 
                                id="hand-print1" 
                                onChange={()=>dispatch(setShirtHand(1))} 
                                checked={shirtHand === 1 ? true : false}
                            />
                            <label htmlFor="hand-print1">
                                <img src="assets/images/anchor-pic.png" width="41" height="45" alt="" />
                            </label>
                        </div>
                        <div className="input-item input-item_type_hand">
                            <input 
                                type="checkbox" 
                                id="hand-print2" 
                                onChange={()=>dispatch(setShirtHand(2))} 
                                checked={shirtHand === 2 ? true : false}
                            />
                            <label htmlFor="hand-print2">
                                <img src="assets/images/seastar-pic.png" width="47" height="49" alt="" />
                            </label>
                        </div>
                        <div className="input-item input-item_type_hand">
                            <input 
                                type="checkbox" 
                                id="hand-print3" 
                                onChange={()=>dispatch(setShirtHand(3))} 
                                checked={shirtHand === 3 ? true : false}
                            />
                            <label htmlFor="hand-print3">
                                <img src="assets/images/flowers-pic.png" width="45" height="52" alt="" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-block__caption">Принт снизу</p>
                    <div className="filter-block__items">
                        <div className="input-item input-item_type_bottom">
                            <input 
                                type="checkbox" 
                                id="bottom-print1"
                                onChange={()=>dispatch(setShirtBottom(1))} 
                                checked={shirtBottom === 1 ? true : false}
                            />
                            <label htmlFor="bottom-print1">
                                <img src="assets/images/print-bottom1.png" width="61" height="61" alt="" />
                            </label>
                        </div>
                        <div className="input-item input-item_type_bottom">
                            <input 
                                type="checkbox" 
                                id="bottom-print2"
                                onChange={()=>dispatch(setShirtBottom(2))} 
                                checked={shirtBottom === 2 ? true : false}
                            />
                            <label htmlFor="bottom-print2">
                                <img src="assets/images/print-bottom2.png" width="61" height="61" alt="" />
                            </label>
                        </div>
                    </div>
                </div>
                <button className="button button_color_white filter-wrapper__button" onClick={createShirt}>Создать футболку</button>
            </div>
            <img className="common-page__logo" src="/assets/images/bottom-logo.png" width="204" height="67" alt="" />
        </div>
    );
}

export default ConstructPage;
