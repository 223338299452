import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setShirtResult} from "../store/mainReducer"
import ServerConnect from '../service'

const TypePage = () => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state=>state.main.launch_params)

    const giveRandomShirt = ()=> {
        ServerConnect.sendGet('/stats_random_btn', {...launch_params})
        dispatch(setShirtResult(undefined))
        dispatch(setActivePanel('loadingpage'))
    }

    return (
        <div className="container common-page">
            <div className="preview-block">
                <p className="caption preview-block__caption">Щас соберем!</p>
                <img className="preview-block__image" src="/assets/images/t-shirt-preview.png" width="360" alt="" />
                <div className="preview-block__buttons">
                    <button className="button button_color_white" onClick={()=>{ServerConnect.sendGet('/stats_choose_btn', {...launch_params}), dispatch(setActivePanel('constructpage'))}}>Выбрать принт</button>
                    <button className="button button_color_transparent preview-block__randdom-button" onClick={giveRandomShirt}>
                        <img src="/assets/images/random-icon.svg" width="26" height="28" alt="" />
                        <span>Сделайте за меня</span>
                    </button>
                </div>
            </div>
            <img className="common-page__logo" src="/assets/images/bottom-logo.png" width="204" height="67" alt="" />
        </div>
    );
}

export default TypePage;
