import React,{useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Init from "./panels/Init";
import bridge from '@vkontakte/vk-bridge';
import Start from './panels/Start';
import TypePage from './panels/TypePage';
import ConstrurcPage from './panels/ConstrurcPage';
import {setUser, setLaunchParams} from './store/mainReducer'
import ServerConnect from './service';
import LoadingPage from './panels/LoadingPage';
import ResultPage from './panels/ResultPage';


const App = () => {

  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)

  useEffect(() => {
    async function fetchData() {
      const launch_params = await bridge.send('VKWebAppGetLaunchParams');
      if (launch_params) {
        return launch_params;
      }
    }
    fetchData().then(data => {dispatch(setLaunchParams(data)), ServerConnect.sendGet('/users', {...data}).then(data=>dispatch(setUser(data)))})
  }, [])

  let content = []
  if (activePanel === 'init') {
    content.push(<Init key='init'/>)
  } else if (activePanel === 'start') {
    content.push(<Start key='start'/>)
  } else if (activePanel === 'constructpage') {
    content.push(<ConstrurcPage key='constructpage'/>)
  } else if (activePanel === 'typepage') {
    content.push(<TypePage key='typepage'/>)
  } else if (activePanel === 'resultpage') {
    content.push(<ResultPage key='resultpage'/>)
  } else if (activePanel === 'loadingpage') {
    content.push(<LoadingPage key='loadingpage'/>)
  } 

  return (
      <div className='app'>
        <>
          {content}
        </>
      </div>
  );
}
export default App;
