import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer"
import bridge from '@vkontakte/vk-bridge';
import ServerConnect from '../service'

const ResultPage = () => {
    const dispatch = useDispatch()
    const shirtResult = useSelector(state=>state.main.shirtResult)
    const user = useSelector(state=>state.main.user)
    const launch_params = useSelector(state=>state.main.launch_params)

    async function share()  {
      ServerConnect.sendGet('/stats_share_btn', {...launch_params})
      bridge.subscribe(event => {
        if (!event.detail) { return; }
        const { type, data } = event.detail;
        if (type === 'VKWebAppShowWallPostBoxResult') {
            ServerConnect.sendGet('/shared', {...launch_params})
        }
      });

      let params = {
          "owner_id": launch_params.vk_user_id,
          "message": 'Это моя футболка от ТНТ 😎 Собери свою и получи стикеры в приложении 🔥👇',
          "attachments": "https://vk.com/app51451538",
          "link_button": 'open_url',
          "link_image": `https://tnt-shirt.gamesmm.ru/assets/images/share/share-${shirtResult}.png`,
      }
      bridge.send("VKWebAppShowWallPostBox", params)
        .then(data=>{
          console.log('share')
        })
        .catch(err=>console.log(err));
    }

   async function story() {
      ServerConnect.sendGet('/stats_story_btn', {...launch_params})
      bridge.subscribe(event => {
        if (!event.detail) { return; }
        const { type, data } = event.detail;
        if (type === 'VKWebAppShowStoryBoxLoadFinish') {
          ServerConnect.sendGet('/storied', {...launch_params})
        }
      });

      let url = `https://tnt-shirt.gamesmm.ru/assets/images/share/story-${shirtResult}.png`
      bridge.send("VKWebAppShowStoryBox", {
          "background_type" : "image",
          "url": url,
          "attachment": {
              "text": "open",
              "type": "url",
              "url": "https://vk.com/app51451538"
          }})
        .then(data=>{
          console.log('story')
        })
        .catch(err=>console.log(err));
    }

    async function permission()  {
      ServerConnect.sendGet('/stats_permission_btn', {...launch_params})
      bridge.subscribe(event => {
        if (!event.detail) { return; }
        const { type, data } = event.detail;
        if (type === 'VKWebAppAllowMessagesFromGroupResult') {
            console.log('разрешение получено')
            dispatch(setUser({...user, part1: true}))
            ServerConnect.sendGet('/part1', {...launch_params})
        } else if (type === 'VKWebAppAllowMessagesFromGroupFailed') {
            console.log('разрешение не получено')
      }
      });

      bridge.send("VKWebAppAllowMessagesFromGroup", {"group_id": 48512305})
    }
    let content = []
    if (user.part1 && user.part2) {content.push(
      <div className="info-block__header-caption info-block__header-caption_type_result-text">
        <p>Кайф, ты собрал все стикеры! Осталось только одно - смотреть новый сезон “Отпуска” с понедельника по четверг в 20:00 на ТНТ! 😏</p>
      </div>
    )} else if (!user.part1) {content.push(
      <>
        <div className="info-block__header-caption">
            <img src="/assets/images/info-pic.png" width="81" height="81" alt="" />
            <p>Разреши доступ,<br/>и получи стикеры!</p>
        </div>
        <button onClick={()=>permission()} className="button button_color_white">Разрешить доступ</button>
      </>
    )} else {content.push(
      <div className="info-block__header-caption info-block__header-caption_type_result-text">
        <p>Хочешь еще стикеры?! 🔥 <br/>Тогда смотри премьеру нового сезона “Отпуска” 31 октября в 20:00 на ТНТ и лови QR-код! Он приведет тебя к стикерам и принесет еще больше курортного настроения! 💃
</p>
      </div>
    )}

    async function status(status_id) {
      ServerConnect.sendGet('/stats_status_btn', {...launch_params})
      bridge.subscribe(event => {
        if (!event.detail) { return; }
        const { type, data } = event.detail;
        if (type === 'VKWebAppAccessTokenReceived') {
            let params = {"method": "status.setImage", "request_id": Math.floor(Math.random() * (1000 - 1) + 1), "params":{"status_id": status_id, "v":"5.131", "access_token": data.access_token}}
            bridge.send("VKWebAppCallAPIMethod", params);
        }
      });

      bridge.send("VKWebAppGetAuthToken", { 
        "app_id": 51451538, 
        "scope": "status"
      });
    }

    return (
        <div className="container common-page">
            <div className="result-wrapper">
                <p className="caption result-wrapper__caption">Получилось 🔥🔥🔥</p>
                <div className="result-wrapper__image">
                    <img src={`/assets/images/shirt-result/shirt-${shirtResult}.png`} width="253" height="280" alt="" />
                </div>
                <button className="button button_color_transparent result-wrapper__reload-button" onClick={()=>dispatch(setActivePanel('typepage'))}>Создать еще</button>
                <div className="info-block">
                    <div className="info-block__header">
                        {content}
                    </div>
                    <div className="info-white">
                        <p className="info-white__text">Поделись своим дизайном курортной футболки, чтобы участвовать в&nbsp;розыгрыше</p>
                        <div className="info-white__buttons">
                            <button onClick={()=>story()} className="button button_color_orange info-white__button">
                                <img src="/assets/images/insta-icon.svg" alt="" />
                                <span>В истории</span>
                            </button>
                            <button onClick={()=>share()} className="button button_color_orange info-white__button">
                                <img src="/assets/images/share-icon.svg" alt="" />
                                <span>На стене</span>
                            </button>
                        </div>
                        <p className="info-white__attention">Установи экслюзивный эмодзи-статус себе в&nbsp;профиль, чтобы словить вайб Отпуска!</p>
                        <div className="vibe-buttons">
                            <div className="input-vibe">
                                <input type="radio" name="status-input" id="vibe1" onChange={()=>status(723)}/>
                                <label htmlFor="vibe1">
                                    <img src="assets/images/sun-pic.png" width="28" height="28" alt="" />
                                    <span>Настроение<br/>лето</span>
                                </label>
                            </div>
                            <div className="input-vibe">
                                <input type="radio" name="status-input" id="vibe2" onChange={()=>status(721)}/>
                                <label htmlFor="vibe2">
                                    <img src="assets/images/weekend-pic.png" width="28" height="28" alt="" />
                                    <span>Хочу<br/>Отпуск!</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="info-bottom">
                        <p className="info-bottom__caption">Выиграй Свою курортную футболку!</p>
                        <p className="info-bottom__description">Поделись своей футболкой на&nbsp;стене или в&nbsp;сторис и&nbsp;участвуй в&nbsp;розыгрыше</p>
                        <a href="https://vk.com/topic-48512305_49101393" target="_blank" className="button button_color_white">Узнать больше</a>
                    </div>
                </div>
            </div>
            <img className="common-page__logo" src="/assets/images/bottom-logo.png" width="204" height="67" alt="" />
        </div>
    );
}

export default ResultPage;
