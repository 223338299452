import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"
import ServerConnect from '../service'


const Start = () => {
    const dispatch = useDispatch()
    const launch_params = useSelector(state=>state.main.launch_params)

    return (
        <div className="container start-page">
            <img className="start-page__logo" width="295" height="224" src="assets/images/main-logo.png" alt="" />
            <p className="start-page__caption">СОЗДАЙ СВОЮ<br/>КУРОРТНУЮ ФУТБОЛКУ</p>
            <p className="text start-page__text">Участвуй в розыгрыше и забирай себе стикеры с главными героями сериала!</p>
            <div className="start-page__buttons">
                <button className="button button_color_white" onClick={()=>{ServerConnect.sendGet('/stats_start_btn', {...launch_params}), dispatch(setActivePanel('typepage'))}}>Хочу футболку</button>
                <a href="https://vk.com/topic-48512305_49101393" target="_blank" className="button button_color_transparent">Узнать подробнее</a>
            </div>
        </div>
    );
}

export default Start;
