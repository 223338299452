import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setShirtResult} from "../store/mainReducer"


const LoadingPage = () => {
    const dispatch = useDispatch()
    const shirtResult = useSelector(state=>state.main.shirtResult)

    const calculateRandomShirt = ()=>{
        const randomPrint = Math.floor(Math.random() * 3)+1
        const randomHand = Math.floor(Math.random() * 2)+1
        const randomBottom = Math.floor(Math.random() * 1)+1
        const randomShirtResult = [randomPrint, randomHand, randomBottom].join('');
        dispatch(setShirtResult(randomShirtResult))
    }

    if(shirtResult === undefined){
        calculateRandomShirt()
    }

    useEffect(() => {
        const loading = setTimeout(() => {
            dispatch(setActivePanel('resultpage'))
        }, 4000);
        return () => clearTimeout(loading);
      }, []);

    return (
        <div className="container common-page">
            <div className="loading-loop">
                <div className="loading-loop__wrapper">
                    <img src="/assets/images/loading-loop.png" width="920" height="267" alt="" />
                </div>
                <p className="loading-block__caption caption">Делаем<br/>красоту...</p>
            </div>
            <img className="common-page__logo" src="/assets/images/bottom-logo.png" width="204" height="67" alt="" />
        </div>
    );
}

export default LoadingPage;

