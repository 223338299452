import React from 'react';
import { useEffect } from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"


const Init = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const loading = setTimeout(() => {
            dispatch(setActivePanel('start'))
        }, 4000);
        return () => clearTimeout(loading);
      }, []);

    return (
    <div className="container init-page">
        <div className="loading-wrapper">
            <img className="loading-wrapper__logo" width="295" height="224" src="assets/images/main-logo.png" alt="" />
            <div className="loading-block container__loading-block">
                <div className="loading-block__bar"></div>
            </div>
        </div>
    </div>
    );
}

export default Init;

