import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        user: null,
        shirtPrint: 1,
        shirtHand: 1,
        shirtBottom: 1,
        shirtResult: 111,
        launch_params: null
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setLaunchParams(state, action) {
            state.launch_params = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setShirtPrint(state,action){
            state.shirtPrint = action.payload
        },
        setShirtHand(state,action){
            state.shirtHand = action.payload
        },
        setShirtBottom(state,action){
            state.shirtBottom = action.payload
        },
        setShirtResult(state,action){
            state.shirtResult = action.payload
        }
    }
})

export default mainSlice.reducer
export const {
    setActivePanel, 
    setUser, 
    setShirtPrint,
    setShirtHand,
    setShirtBottom,
    setShirtResult,
    setLaunchParams
} = mainSlice.actions
